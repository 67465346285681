import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import actions
import { fetchSubscriberDevicesSn } from 'store/actions';

// Import hooks
import { useToggle } from 'hooks';

// Import components
import {
	Ibox,
	IboxTitle,
	IboxContentBase,
	Wraper
} from 'components/common/layout';
import { ReactServerSideTable } from 'components/utilities/table';
import { Button } from 'antd';
import SubscribersDevicesSnModal from './subscribers_devices_sn_form/SubscribersDevicesSnModal';

// Import hooks
import { useSubscribersDevicesSn } from './useSubscribersDevicesSn';

const SubscribersDevicesSn = ({ fetchSubscriberDevicesSn }) => {
	const { t } = useTranslation('subscribers');
	const [open, toggle] = useToggle(false);
	const devicesSn = useSelector((state) => state.subscribers.devicesSn);
	const {
		columns,
		isReadOnly,
		subscriberId,
		isLoading
	} = useSubscribersDevicesSn();

	return (
		<Wraper lg="12" error={devicesSn.error}>
			<Ibox error={false} className="m-b-lg">
				<IboxTitle title="Urządzenia SN" isLoaded={false}>
					{!isReadOnly && (
						<div className="d-flex justify-content-end">
							<Button
								type="primary"
								htmlType="button"
								onClick={toggle}
								className="ml-3"
							>
								{t('buttons.add_device')}
							</Button>
							<SubscribersDevicesSnModal
								isVisible={open}
								onCancel={toggle}
								subscriberId={subscriberId}
								setModalVisibility={toggle}
							/>
						</div>
					)}
				</IboxTitle>

				<IboxContentBase isLoaded={!isLoading}>
					<ReactServerSideTable
						resources={devicesSn}
						columns={columns}
						itemId={subscriberId}
						fetchResourcesAction={fetchSubscriberDevicesSn}
						tableType="subscriber_devices_sn"
					/>
				</IboxContentBase>
			</Ibox>
		</Wraper>
	);
};

SubscribersDevicesSn.propTypes = {
	fetchSubscriberDevicesSn: PropTypes.func.isRequired
};

export default connect(null, { fetchSubscriberDevicesSn })(
	SubscribersDevicesSn
);
