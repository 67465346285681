import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import {
	getLocationType,
	convertPrice,
	convertPriceToCents
} from 'components/helpers/convert';
import { convertLocationType } from '../tabs/helpers/helpers';
import { SYSTEM_SETTINGS_FIELDS } from 'components/helpers/system_settings_helpers/system_settings_fields';
import {
	checkIsAvailability,
	checkIsPrices,
	checkForEpg,
	checkIsCouponsSystemSettings
} from 'components/helpers/pathname';

// Import utilities
import { submitForm } from 'components/utilities/form';

const useSystemSettingsForm = () => {
	const { pathname } = useLocation();
	const history = useHistory();

	const settings = useSelector(
		({ system_settings }) => system_settings.form.edit
	);

	const isEpg = checkForEpg(pathname);
	const isAvailability = checkIsAvailability(pathname);
	const isPrices = checkIsPrices(pathname);
	const isCouponsSystemSettings = checkIsCouponsSystemSettings(pathname);

	const type = convertLocationType(
		getLocationType(pathname),
		isEpg,
		isAvailability,
		isPrices,
		isCouponsSystemSettings
	);

	const handleOnSubmit = ({ isEdit, itemID }) => async (values) => {
		// We can't send this value, because system is sending as query parameter
		delete values.system;

		const stb_price = values.stb_price
			? convertPriceToCents(values.stb_price)
			: null;

		// Submit the form with field values
		return await submitForm({
			history,
			isEdit,
			itemID,
			resources: { ...values, stb_price },
			api: `${type}/systemFields`,
			withoutSlug: true,
			notificationName: 'system'
		});
	};

	const { defaultFieldValues, validate } = SYSTEM_SETTINGS_FIELDS[type];

	const stb_price = settings.stb_price
		? convertPrice(settings.stb_price)
		: null;

	const initialValues = {
		...defaultFieldValues,
		...settings,
		stb_price
	};

	return {
		type,
		initialValues,
		validate,
		onSubmit: handleOnSubmit
	};
};

export default useSystemSettingsForm;
