import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import actions
import { fetchSystemSettings } from 'store/actions';

// Import components
import { FormTemplate } from 'components/common/templates';
import { FormButtons } from 'components/common/buttons';
import Fields from './fields/Fields';

// Import hooks
import useSystemSettingsForm from './useSystemSettingsForm';

const SystemSettingsForm = ({ fetchSystemSettings }) => {
	const { t } = useTranslation();

	const prevPath = useSelector((state) => state.previous_location.pathname);

	const { error, isLoaded } = useSelector(
		({ system_settings }) => system_settings.form
	);

	// Remove "/panel/" from prevPath;
	const redirectPath = prevPath.slice(7);

	const { type, initialValues, validate, onSubmit } = useSystemSettingsForm();

	return (
		<FormTemplate
			fetchResourceToEdit={fetchSystemSettings}
			error={error}
			isLoaded={isLoaded}
			fetchActionResources={{ type }}
			iboxContentTitle={t('channels:system.config_title')}
		>
			{({ isEdit, itemID }) => (
				<Form
					initialValues={initialValues}
					validate={validate}
					onSubmit={onSubmit({ isEdit, itemID })}
					render={({ handleSubmit, submitting, values }) => (
						<form onSubmit={handleSubmit}>
							<Fields submitting={submitting} type={type} />

							<FormButtons
								isButtonDisabled={submitting || error}
								buttonText={t('common:buttons.submit')}
								path={redirectPath}
							/>
						</form>
					)}
				/>
			)}
		</FormTemplate>
	);
};

SystemSettingsForm.propTypes = {
	fetchSystemSettings: PropTypes.func.isRequired
};

export default connect(null, { fetchSystemSettings })(SystemSettingsForm);
