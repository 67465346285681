import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import services
import services from 'services/services';

// Import actions
import { detachDeviceSn } from 'store/actions';

// Import columns
import { createDeviceColumns } from './subscriber_devices_sn_columns';

// Import hooks
import useAuthCheck from 'hooks/useAuthCheck';

// Import utilities
import { messageConfirmMultipleIdHandler } from 'components/utilities/message';
import { notificationHandler } from 'components/utilities/notifications';

const refreshSubscriberDevice = ({ system, serial_number }) => {
	const url = 'subscriber/notifications/refreshDevice';
	return services.post(url, { serial_number }, { params: { system } });
};

export const useSubscribersDevicesSn = () => {
	const { t } = useTranslation('subscribers');
	const dispatch = useDispatch();
	// check if user have rights to create and edit forms
	const { isReadOnly } = useAuthCheck();

	const { id: subscriberId } = useParams();

	const refreshDevice = useMutation({
		mutationFn: refreshSubscriberDevice,
		onSuccess: (_data, variables) => {
			notificationHandler(
				t('common:success'),
				t('devices_sn.notifications.refresh_success', variables),
				'success'
			);
		},
		onError: (_error, variables) => {
			notificationHandler(
				t('common:error'),
				t('devices_sn.notifications.refresh_error', variables),
				'error'
			);
		}
	});

	const handleDeviceDetach = (deviceId) => {
		messageConfirmMultipleIdHandler({
			callback: () => detachDeviceSn(subscriberId, deviceId)(dispatch),
			title: t('devices_sn.detach_confirmation.title'),
			content: t('devices_sn.detach_confirmation.content')
		});
	};

	const handleRefreshDevice = (system, serial_number) => {
		messageConfirmMultipleIdHandler({
			callback: refreshDevice.mutate,
			title: t('devices_sn.refresh_device_confirmation.title'),
			content: t('devices_sn.refresh_device_confirmation.content', {
				serial_number
			}),
			ids: [{ system, serial_number }]
		});
	};

	const columns = useMemo(
		() =>
			createDeviceColumns(handleDeviceDetach, handleRefreshDevice, isReadOnly),
		// eslint-disable-next-line
		[isReadOnly]
	);

	return {
		columns,
		isReadOnly,
		subscriberId,
		isLoading: refreshDevice.isLoading
	};
};
