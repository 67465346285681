import i18n from 'i18next';

// Import helpers
import { checkDecimalPlaces } from 'components/common/templates/helpers';

export const PACKET_SYSTEM_SETTINGS_FIELDS = {
	defaultFieldValues: {
		active: false,
		stb_price: null,
		visible_primary: false,
		visible_secondary: false
	},
	fields: [
		{
			name: 'active',
			label: i18n.t('common:form.active'),
			type: 'checkbox'
		},
		{
			name: 'stb_price',
			label: i18n.t('common:form.stb_price'),
			type: 'number',
			step: 0.01
		}
	],
	visibleFields: [
		{
			name: 'visible_primary',
			label: i18n.t('common:form.visible_primary'),
			type: 'checkbox'
		},
		{
			name: 'visible_secondary',
			label: i18n.t('common:form.visible_secondary'),
			type: 'checkbox'
		}
	],
	validate: ({ stb_price }) => {
		let errors = {};

		if (stb_price) {
			if (stb_price <= 0) {
				errors.stb_price = i18n.t('prices:validation.price_length');
			} else if (checkDecimalPlaces(stb_price) >= 3) {
				errors.price = i18n.t('prices:validation.price_step');
			}
		}

		return errors;
	}
};
