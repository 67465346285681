import React from 'react';
import { Link } from 'react-router-dom';

// Import translation
import i18n from 'i18next';

// Import helpers
import { renderSystem } from 'components/helpers/system';

// Import components
import ActiveIcon from 'components/utilities/table/render_icons/ActiveIcon';

export const createDeviceColumns = (
	deviceDetach,
	refreshDevice,
	isReadOnly
) => [
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.active'),
		accessor: 'active',
		width: 75,
		sortable: false,
		headerClassName: 'text-center',
		className: 'text-center',
		Cell: ({
			row: {
				_original: { active }
			}
		}) => <ActiveIcon active={active} />
	},
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.login'),
		accessor: 'login',
		className: 'scroll-cell'
	},
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.serial_number'),
		accessor: 'serial_number',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { id, serial_number }
			}
		}) => (
			<Link to={`/panel/devices_sn/edit/${id}/metadata`}>{serial_number}</Link>
		)
	},
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.os_system'),
		accessor: 'system',
		className: 'scroll-cell',
		Cell: ({
			row: {
				_original: { system }
			}
		}) => <span>{renderSystem(system)}</span>
	},
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.refresh_device'),
		accessor: 'refresh_device',
		width: 170,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { system, serial_number }
			}
		}) => (
			<button
				onClick={refreshDevice.bind(null, system, serial_number)}
				className="btn btn-xs btn-primary"
			>
				<i className="fa fa-refresh" />
			</button>
		)
	},
	{
		Header: i18n.t('subscribers:devices_sn.table_columns.detach'),
		accessor: 'detach',
		width: 90,
		className: 'text-center',
		sortable: false,
		Cell: ({
			row: {
				_original: { id }
			}
		}) => (
			<button
				onClick={deviceDetach.bind(null, id)}
				className="btn btn-xs btn-primary"
				disabled={isReadOnly}
			>
				<i className="fa fa-exchange" />
			</button>
		)
	}
];
